import { MetaData, HeaderPromo, FooterRegistration, HomePage } from '@/components'
import { fetchProjects } from '@/store/slices/projectsSlice'
import { initializeStore } from '@/store'
import { Layout } from '@/components'
import { NextPage } from 'next'

const IndexPage: NextPage = () => {
  return (
    <Layout theme="dark">
      {{
        headerContent: <HeaderPromo />,
        mainContent: (
          <>
            <MetaData />
            <HomePage />
          </>
        ),
        footerContent: <FooterRegistration />,
      }}
    </Layout>
  )
}

IndexPage.getInitialProps = async () => {
  const reduxStore = initializeStore()
  const { dispatch } = reduxStore
  await dispatch(fetchProjects(7))

  return {
    initialReduxState: reduxStore.getState(),
  }
}

export default IndexPage
